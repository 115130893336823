<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-app-bar color="#3d673A">
      <v-layout row justify-space-between align-center px-3>
        <!-- Logo Section (Left) -->
        <v-flex class="d-sm-none d-flex justify-center">
          <v-img
            max-width="100px"
            max-height="100px"
            src="../assets/Images/wtilogo.png"
          ></v-img>
        </v-flex>

        <v-flex xs4 sm2 md2 lg2 class="hidden-xs-only">
          <v-img
            max-width="100px"
            max-height="100px"
            src="../assets/Images/wtilogo.png"
          ></v-img>
        </v-flex>
      </v-layout>
    </v-app-bar>

    <v-layout wrap justify-center>
      <v-flex xs12 class="hidden-sm-and-up">
        <v-img src="../assets/Images/supportwildlifewebp.webp" height="100vh" cover>
          <v-container
            fill-height
            class="d-flex flex-column text-center"
            style="height: 100%; justify-content: flex-start; padding-top: 30%"
          >
            <span class="popregular help-text">Wild India needs your help</span>
            <v-btn
              color="#ecb338"
              style="color: white; margin-top: 10px"
              @click="openDonationDialog"
              >GIVE TODAY</v-btn
            >
          </v-container>
        </v-img>
      </v-flex>
      <v-flex xs12 class="hidden-xs-only">
        <v-img src="../assets/Images/supportwildlifewebp.webp" height="100vh" cover>
          <v-container
            fill-height
            class="d-flex flex-column pt-16 mt-8 text-center"
            style="height: 100%"
          >
            <span class="popregular help-text">Wild India needs your help</span>
            <v-btn
              color="#ecb338"
              style="color: white; margin-top: 10px"
              @click="openDonationDialog"
              >GIVE TODAY</v-btn
            >
          </v-container>
        </v-img>
      </v-flex>
    </v-layout>
    <v-dialog v-model="donationDialog" persistent max-width="800px">
      <v-card elevation="3">
        <v-card-title style="background-color: #f2f2f2">
          <span>Select an Amount</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="donationDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-card elevation="0" v-if="firstCard" class="animate2" id="div2">
                <v-layout wrap justify-center pa-3 pa-sm-0>
                  <v-flex xs12>
                    <v-layout wrap pt-8 pa-8 class="popregular">
                      <v-flex xs12 sm6 md12 lg6 pa-2>
                        <v-btn
                          @click="donation = BTN1"
                          outlined
                          block
                          height="40px"
                          color="#e27725"
                          style="font-size: 20px"
                          dark
                          >{{ sign }}{{ BTN1 }}</v-btn
                        >
                      </v-flex>
                      <v-flex xs12 sm6 md12 lg6 pa-2>
                        <v-btn
                          @click="donation = BTN2"
                          height="40px"
                          color="#e27725"
                          dark
                          style="font-size: 20px"
                          block
                          >{{ sign }}{{ BTN2 }}</v-btn
                        >
                      </v-flex>
                      <v-flex xs12 sm6 md12 lg6 pa-2>
                        <v-btn
                          @click="donation = BTN3"
                          height="40px"
                          color="#e27725"
                          dark
                          style="font-size: 20px"
                          block
                          >{{ sign }}{{ BTN3 }}</v-btn
                        >
                      </v-flex>
                      <v-flex xs12 sm6 md12 lg6 pa-2>
                        <v-btn
                          id="my_button"
                          @click="toTextField()"
                          height="40px"
                          color="#e27725"
                          dark
                          class="anyotheramt"
                          block
                          >Any other amount</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 px-10>
                    <v-text-field
                      type="number"
                      id="my_textbox"
                      height="40px"
                      outlined
                      v-model="donation"
                      class="center"
                    >
                      <template v-slot:prepend-inner>
                        <v-layout justify-center pt-3>
                          <v-flex
                            xs12
                            class="align-self-center"
                            style="border-right: 1px dotted grey"
                          >
                            <span
                              style="
                                padding-right: 2px;
                                font-family: poppinsregular;
                                font-size: 12px;
                                color: #000;
                              "
                              >{{ Cname + sign }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 py-8>
                    <v-btn
                      @click="(secondCard = true), (firstCard = false), login()"
                      height="40px"
                      color="#e27725"
                      dark
                      style="font-size: 24px; text-transform: none"
                      block
                      >Continue <v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-flex>
                  <v-flex
                    class="popregular"
                    xs12
                    py-5
                    text-center
                    style="background-color: #f2f2f2"
                  >
                    <span><v-icon small>mdi-lock</v-icon> </span>
                    <span>Secure Donation</span>
                  </v-flex>
                </v-layout>
              </v-card>

              <v-card elevation="3" v-if="secondCard">
                <v-form ref="file">
                  <v-layout wrap justify-center pa-3 class="animate" id="div1">
                    <v-flex xs12>
                      <v-layout wrap style="background-color: #f2f2f2">
                        <v-flex
                          xs1
                          align-self-center
                          style="background-color: #f2f2f2"
                        >
                          <v-icon
                            @click="(firstCard = true), (secondCard = false)"
                            >mdi-chevron-left</v-icon
                          >
                        </v-flex>
                        <v-flex
                          xs10
                          py-3
                          style="
                            background-color: #f2f2f2;
                            margin-bottom: 1px solid grey;
                          "
                        >
                          <span class="popregular ml"
                            >Add Your Information.</span
                          >
                        </v-flex>
                        <v-flex xs1 py-3>
                          <v-spacer></v-spacer>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 px-5 pt-8>
                      <v-text-field
                        class="center2"
                        type="number"
                        ref="amount"
                        color="#828282"
                        placeholder="Amount"
                        dense
                        label="Amount"
                        :prepend-inner-icon="sign"
                        outlined
                        rounded
                        :rules="[rules.required]"
                        v-model="donation"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        ref="firstname"
                        color="#828282"
                        prepend-inner-icon="mdi-account"
                        label="First Name"
                        rounded
                        dense
                        outlined
                        class="center2"
                        :rules="[rules.required]"
                        v-model="firstname"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        ref="lastname"
                        color="#828282"
                        prepend-inner-icon="mdi-account"
                        label="Last Name"
                        rounded
                        dense
                        outlined
                        class="center2"
                        :rules="[rules.required]"
                        v-model="lastname"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        ref="email"
                        color="#828282"
                        prepend-inner-icon="mdi-email"
                        outlined
                        rounded
                        class="center2"
                        dense
                        label="Email"
                        :rules="[rules.email]"
                        v-model="email"
                      ></v-text-field>
                    </v-flex>
                    <template v-if="taxExemption == true">
                      <v-flex xs12 px-5>
                        <v-text-field
                          color="#828282"
                          prepend-inner-icon="mdi-pencil"
                          outlined
                          rounded
                          dense
                          class="center2"
                          label="Address"
                          :rules="[rules.required]"
                          v-model="Newaddress"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 px-5>
                        <v-text-field
                          color="#828282"
                          prepend-inner-icon="mdi-pen"
                          outlined
                          rounded
                          dense
                          class="center2"
                          label="City"
                          :rules="[rules.required]"
                          v-model="Newcity"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 px-5>
                        <v-text-field
                          color="#828282"
                          prepend-inner-icon="mdi-pen"
                          outlined
                          rounded
                          class="center2"
                          dense
                          label="Pincode"
                          :rules="[rules.required]"
                          v-model="Newpincode"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 px-5>
                        <v-text-field
                          color="#828282"
                          prepend-inner-icon="mdi-pen"
                          outlined
                          dense
                          rounded
                          class="center2"
                          label="Pan Number"
                          :rules="[rules.required]"
                          v-model="Newpannumber"
                        ></v-text-field>
                      </v-flex>
                    </template>

                    <v-flex xs12 px-5 pt-2>
                      <v-checkbox
                        v-model="taxExemption"
                        hide-details=""
                        class="LBL"
                        :value="true"
                        label="I wish to claim tax exemption against this donation"
                      ></v-checkbox>
                    </v-flex>

                    <v-flex xs12 px-5>
                      <v-checkbox
                        v-model="isanonymous"
                        hide-details=""
                        class="LBL"
                        :value="true"
                        label="Make my donation anonymous "
                      ></v-checkbox>
                    </v-flex>

                    <v-flex xs12 px-5>
                      <v-checkbox
                        v-model="subscribe"
                        hide-details=""
                        class="LBL"
                        :value="true"
                        label="
                  Subscribe to our newsletter for inspiring stories from our
                  projects"
                      ></v-checkbox>
                    </v-flex>

                    <v-flex xs12 sm6 py-8>
                      <v-btn
                        @click="validate()"
                        height="40px"
                        color="#e27725"
                        dark
                        style="
                          font-size: 20px;
                          text-transform: none;
                          font-family: poppinsregular;
                        "
                        block
                        >Donate Now
                      </v-btn>
                    </v-flex>

                    <v-flex
                      class="popregular"
                      xs12
                      py-5
                      text-center
                      style="background-color: #f2f2f2"
                    >
                      <span><v-icon small>mdi-lock</v-icon> </span>
                      <span>Secure Donation</span>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      Newaddress: "",
      Newcity: "",
      Newpincode: "",
      Newpannumber: "",

      //inherited varibles from donation Card
      BTN1: 5000,
      BTN2: 10000,
      BTN3: 20000,
      sign: "₹",
      Cname: "INR",

      country: "India",
      amount: null,
      firstname: null,
      lastname: null,
      mobile: null,
      email: null,
      address: null,
      city: null,
      // phone:"",
      state: null,
      postalcode: null,
      panno: null,
      hearaboutus: "",
      comments: null,
      isanonymous: false,
      subscribe: false,
      taxExemption: false,
      symbol: "₹",
      regionssymbol: [],
      message: "",
      resource: null,
      regions: [],

      //end of inherited variables from donationCard
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      donation: 5000,
      firstCard: true,
      secondCard: false,
      radio: null,
      showSnackBar: false,
      msg: "",
      ServerError: false,
      timeout: 3000,
      appLoading: false,
      donationDialog: false,
    };
  },
  watch: {
    country() {
      if (this.country != "India") {
        this.BTN1 = 100;
        this.BTN2 = 200;
        this.BTN3 = 500;
        this.sign = "$";
        this.Cname = "USD";
        this.donation = this.BTN1;
        //  console.log("true not india")
      } else {
        this.BTN1 = 5000;
        this.BTN2 = 10000;
        this.BTN3 = 20000;
        this.sign = "₹";
        this.Cname = "INR";

        this.donation = this.BTN1;

        //  console.log("false india")
      }
    },
  },
  beforeMount() {
    axios({
      method: "GET",
      url: "/country/all",
    })
      .then((response) => {
        this.regions = response.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.getData();
  },
  methods: {
    login() {
      this.$gtag.event("login", { method: "Google" });
    },
    openDonationDialog() {
      this.donationDialog = true;
      // Reset cards to initial state
      this.firstCard = true;
      this.secondCard = false;
    },

    toTextField() {
      this.donation = null;
      document.getElementById("my_button").onclick = function () {
        document.getElementById("my_textbox").focus();
      };
    },
    validate() {
      if (this.$refs.file.validate()) {
        var don = {};
        this.appLoading = true;
        don["country"] = this.country;
        don["amount"] = this.donation;
        don["firstname"] = this.firstname;
        don["lastname"] = this.lastname;
        // don["donationtype"] = this.$route.query.DonationName;
        don["email"] = this.email;
        don["isanonymous"] = this.isanonymous;
        don["subscribe"] = this.subscribe;
        don["address"] = this.Newaddress;
        don["city"] = this.Newcity;
        //  don["phone"] = this.phone;
        // don["panNumber"] = this.Newpannumber;
        don["taxExemption"] = this.taxExemption;
        don["postalcode"] = this.Newpincode;
        don["panno"] = this.Newpannumber;

        // taxExemption
        axios({
          method: "post",
          url: "/payment/initiate",
          data: don,
        })
          .then((response) => {
            if (response.data.status) {
              localStorage.setItem("currencySymbol", this.symbol);
              this.appLoading = false;
              this.$router.push(
                "/support-wildlife-payment/?id=" +
                  response.data.id +
                  "&country=" +
                  this.country +
                  "&event=" +
                  this.$route.query.DonationName
              );
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch(() => {
            this.appLoading = false;
            this.ServerError = true;
          });
      } else {
        this.showSnackBar = true;
        this.msg = "Please complete your form";
      }
    },
  },
};
</script>
  <style  scoped>
.LBL >>> label {
  font-family: poppinsregular !important;
  font-size: 12px !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: grey;
  border-radius: 5px;
}

.v-radio >>> label {
  font-size: 20px;
  font-family: poppinsregular;
  color: black;
}
.center >>> input {
  text-align: center;
  font-family: poppinsregular;
  font-size: 32px;
}
.center2 >>> label {
  text-align: center;
  font-family: poppinsregular;
}
.center4 {
  text-align: center !important;
  font-family: poppinsregular !important;
}
.boldR >>> label {
  font-family: poppinsbold;
}
.animate {
  position: relative;
  animation: mymove 0.25s;
}
.animate2 {
  position: relative;
  animation: mymove2 0.25s;
}
#div2 {
  animation-timing-function: linear;
}
@keyframes mymove2 {
  from {
    left: -200px;
  }
  to {
    left: 0px;
  }
}

@keyframes mymove {
  from {
    right: -500px;
  }
  to {
    right: 0px;
  }
}
#div1 {
  animation-timing-function: linear;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear; /* Safari */
  animation: spin 2s linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.v-checkbox >>> label {
  font-family: poppinsregular;
}
.help-text {
  color: white;
  font-size: 50px;
}

@media (max-width: 600px) {
  .help-text {
    font-size: 30px;
  }
}
.anyotheramt {
  font-size: 16px;
  text-transform: none;
}
@media (max-width: 600px) {
  .anyotheramt {
    font-size: 13px;
  }
}
</style>